import Icon from "app/assets/icon/icon";
import Tooltip from "app/layout/tooltip";
// import ModalHeader from "app/layout/ModalHeader";
import {
  TooltipContent,
  TooltipTriggerElement,
} from "app/layout/tooltip/tooltip";
import {
  MDBCol,
  MDBRow,
  MDBModal,
  MDBModalBody,
  MDBTypography,
} from "mdbreact";
import React, { useContext, useEffect, useState, useCallback } from "react";
import FabricItemMobile from "./fabricItemMobile";
import { calculateUpholsteryOptionSelectorPrice } from "../../options/functions";
import { CustomPriceContext } from "../../customPriceContext";
import themeConfiguration from "config/themeConfiguration";

const FabricItem = (props) => {
  let {
    option,
    preselection,
    setPreselection,
    index,
    isMobile,
    // configFabricProps,
    // setCustomizationOption,
    mainOption,
    isSelected,
    setIsSelected,
    // removeCustomizationOption,
    // fabricOrFinishesSelectedOptions,
    defaultFabric,
    fabricArray,
    // isFabricOnceClicked,
    setIsFabricOnceClicked,
    calculatedUpholsteryOptionsFabrics,
    // rerender,
    setSelectedOption,
    currentOption,
    selectedOption,
    previousOption,
    setPreviousOption,
    selectedFabricOption,
  } = props;
  const [hasBagde, setHasBadge] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(false);
  const [calculatedPrice, setCalculatedPrice] = useState("");
  let urlBuild = window.location.href.indexOf("build-your-own") > -1;

  let addClass = "";
  if (!isMobile) {
    addClass =
      index % 7 === 0 || (index + 6) % 7 === 0
        ? "align-left"
        : (index + 1) % 7 === 0 || (index + 2) % 7 === 0
        ? "align-right"
        : "";
  }

  const {
    customizationOptionPrice,
    // upholsteryOptionsFabrics,
    // customer,
    price,
    // setPrice,
    // dropdownPrice,
    // viewMorePdpPrice,
    // contrastWeltPrice,
    // trimPrice,
    // setTrimPrice,
  } = useContext(CustomPriceContext);

  useEffect(() => {
    const badge = fabricArray.find((item) => item.id === option.sku);
    if (badge) {
      setHasBadge(true);
    } else {
      setHasBadge(false);
    }
  }, [option]);
  useEffect(() => {
    if (showNotification === true) {
      setTimeout(() => {
        setShowNotification(false);
        setIsFabricOnceClicked(false);
      }, 3000);
    }
  }, [isSelected]);

  useEffect(() => {
    if (calculatedUpholsteryOptionsFabrics) {
      if (selectedFabricOption?.name === option?.name || isSelected) return;
      let finalPrice = "";
      finalPrice = currentOption.field_value.price
        ? preselection?.[currentOption.title.toLowerCase().split(" ").join("_")]
          ? "$ " + parseFloat(price).toFixed(2)
          : "$ " +
            (parseFloat(price) + currentOption.field_value.price).toFixed(2)
        : customizationOptionPrice >
          parseFloat(
            calculateUpholsteryOptionSelectorPrice(
              calculatedUpholsteryOptionsFabrics,
              option
            ).substring(2)
          )
        ? "$ " + parseFloat(price).toFixed(2)
        : "$ " +
          (
            parseFloat(price) -
            customizationOptionPrice +
            parseFloat(
              calculateUpholsteryOptionSelectorPrice(
                calculatedUpholsteryOptionsFabrics,
                option
              ).substring(2)
            )
          ).toFixed(2);
      setCalculatedPrice(finalPrice);
    }
  }, [calculatedUpholsteryOptionsFabrics, price]);

  const handleItemSelect = useCallback(
    (option) => {
      if (option) {
        if (isSelected !== option.id) {
          setIsSelected(option.id);
          setSelectedOption(option);
          let localPreselection = JSON.parse(JSON.stringify(preselection));
          localPreselection[
            currentOption.title.toLowerCase().split(" ").join("_")
          ] = option;
          setPreselection(localPreselection);
        }
      } else {
        setIsSelected(false);
        setSelectedOption(null);
      }
    },
    [isSelected, setIsSelected, setPreselection, setSelectedOption]
  );

  return isMobile ? (
    <FabricItemMobile
      option={option}
      handleItemSelect={handleItemSelect}
      setHasBadge={setHasBadge}
      hasBagde={hasBagde}
      isSelected={isSelected}
    />
  ) : (
    <>
      <Tooltip key={option.id} className={`fabric-item-tooltip ${addClass}`}>
        <div className="tooltip-elements-container">
          <TooltipContent
            setVisibleContent={setOpenTooltip}
            visibleContent={openTooltip}
            hasTickArrow={true}
            positionY={"top"}
            positionX={"center"}
          >
            <div>
              <div className={`image-container border-tooltip-img`}>
                <img src={option.image.url} alt={option.image.label} />
              </div>
              <div className="info-container">
                <p className="text-darken-gray">{option.name}</p>
                <p className="text-darken-gray f14">Pattern {option.pattern}</p>
                <p className="text-darken-gray f14">
                  Grade {option.grade?.[0] !== "false" ? option.grade : null}
                </p>
                {themeConfiguration.magento_url !==
                  "https://magento.fairfieldchair.com/" &&
                  calculatedPrice &&
                  calculatedPrice.indexOf("NaN") === -1 && (
                    <p className="text-darken-gray f14">{calculatedPrice}</p>
                  )}
                {/* <p className="text-darken-gray f14">
                  {customizationOptionPrice}
                </p> */}
              </div>
            </div>
          </TooltipContent>
          <TooltipTriggerElement setVisibleContent={setOpenTooltip}>
            <div>
              <MDBCol size="2" className={`fabric-item`}>
                <MDBRow>
                  <MDBCol
                    size="12"
                    onClick={(e) => {
                      !hasBagde && setShowNotification(true);
                      if (option.id === isSelected) {
                        if (
                          currentOption?.title.toLowerCase() === "base trim" ||
                          currentOption?.title.toLowerCase() ===
                            "decorative tape frame" ||
                          currentOption?.title.toLowerCase() === "skirt trim" ||
                          currentOption?.title.toLowerCase() ===
                            "decorative cord" ||
                          currentOption?.title.toLowerCase() === "fringe" ||
                          currentOption?.title.toLowerCase() === "nail band" ||
                          currentOption?.title.toLowerCase() ===
                            "throw pillow contrast welt" ||
                          currentOption?.title.toLowerCase() ===
                            "throw pillow decorative cord" ||
                          currentOption?.title.toLowerCase() ===
                            "throw pillow fringe" ||
                          currentOption?.title.toLowerCase() ===
                            "kidney pillow contrast welt" ||
                          currentOption?.title.toLowerCase() ===
                            "kidney pillow decorative cord" ||
                          currentOption?.title.toLowerCase() ===
                            "kidney pillow fringe"
                        ) {
                          setPreviousOption(currentOption.option_id);
                          handleItemSelect(null);
                        } else {
                          setPreviousOption(false);
                          handleItemSelect(defaultFabric);
                        }
                      } else {
                        setPreviousOption(false);
                        handleItemSelect(option);
                      }
                    }}
                    className={`fabric-has-badge-${
                      urlBuild ? hasBagde : ""
                    } d-flex align-items-center fabric-inner `}
                  >
                    <img
                      src={option.image.url}
                      alt={option.image.label}
                      className={`img-fluid border-gray-1 ${
                        option.id === isSelected
                          ? "border-default border option-selected"
                          : "border-gray-1"
                      }`.trim()}
                      style={{
                        width: "5.6rem",
                        height: "5.6rem",
                        position: "relative",
                      }}
                    />
                    {option.id === isSelected && (
                      <Icon
                        icon="checked"
                        style={{
                          position: "absolute",
                          bottom: 0,
                          right: 0,
                          height: "1.5rem",
                          width: "1.5rem",
                          borderRadius: "5px 0px 5px 0px",
                        }}
                      />
                    )}
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            </div>
          </TooltipTriggerElement>
        </div>
      </Tooltip>
      {/* showNotification && isFabricOnceClicked */}
      {(showNotification && urlBuild && (
        <MDBModal
          className={`notification-modal`}
          backdropClassName={`notification-modal-backdrop`}
          centered
          isOpen={showNotification}
          toggle={() => {
            setShowNotification(!showNotification);
          }}
        >
          <MDBModalBody>
            <MDBTypography tag="p" className={`body-1 text-center`}>
              This selected option is not viewable
            </MDBTypography>
          </MDBModalBody>
        </MDBModal>
      )) || <></>}
    </>
  );
};

export default FabricItem;
