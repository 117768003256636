import desktopLogoDark from "app/assets/logo/desktopLogo.png";
import { DomainCartFragment, DomainCartItem } from "../models";
import themeConfiguration from "config/themeConfiguration";
import Price from "core/components/price";
import { MDBBox, MDBCol, MDBContainer, MDBRow, MDBTypography } from "mdbreact";
import React from "react";
import {
  IQuantityPerPage,
  PrintConfig,
  printConfig,
  quantitiesPerPageRecord,
} from "../config";
import CustomizableOptions from "./CustomizableOptions";

export interface PrintProps {
  quantityPerPage: IQuantityPerPage;
  itemsWithPrintableProducts: DomainCartFragment;
  cartQuery: any;
  showOptionsWithCollapse: boolean;
  classNameNotes: string;
  comment: any;
}

class Print extends React.Component<PrintProps> {
  render() {
    const {
      props: { quantityPerPage, itemsWithPrintableProducts, cartQuery },
    } = this;
    const { colSize, imageHeight, largeFont }: PrintConfig = (() => {
      switch (quantityPerPage) {
        case quantitiesPerPageRecord[1]:
          return {
            ...printConfig,
            colSize: 12,
            imageHeight: "300px",
            heading: 3,
          };
        default:
          return printConfig;
      }
    })();

    const itemsPerPage = itemsWithPrintableProducts.items.map((item) => [item]);

    let productCollection = "";

    return (
      <MDBContainer
        fluid
        style={{ height: "100%" }}
        className={`pdf-one-container bg-white px-5`}
      >
        {Object.values(itemsPerPage).map((items, i) => {
          return (
            <MDBRow
              className={`px-5`}
              key={i}
              style={{
                pageBreakAfter: "always",
              }}
            >
              <MDBCol className="d-flex flex-column">
                <MDBRow>
                  <MDBCol className="py-3 d-flex justify-content-center align-content-center">
                    <a href={`${themeConfiguration.app_url}`}>
                      <img
                        style={{
                          maxWidth: "100%",
                          height: "4rem",
                        }}
                        src={desktopLogoDark}
                        alt="Logo"
                        className="mt-5"
                      />
                    </a>
                  </MDBCol>
                </MDBRow>
                <MDBRow className="w-100 pt-5">
                  <MDBCol size="12">
                    <MDBContainer fluid>
                      <MDBRow>
                        {items.map((item: any, index: any) => {
                          //@ts-ignore
                          let buildImage = item.customizable_options.find(
                            (item: any, index: any) => {
                              return item?.label?.includes(
                                "Build your own image"
                              );
                            }
                          );

                          let showOnePage = [
                            ...item?.customizable_options?.filter(
                              // @ts-ignore
                              (opt) =>
                                !opt?.label
                                  ?.toLowerCase()
                                  ?.includes("build your own")
                            ),
                          ]?.length
                            ? false
                            : true;

                          let buildUrl =
                            themeConfiguration.magento_url +
                            buildImage?.values[0]?.value;

                          productCollection =
                            item?.product?.ffi_collection?.[0];
                          return (
                            <React.Fragment key={item.product.sku}>
                              <MDBCol
                                style={{
                                  height: showOnePage ? "80rem" : "150rem",
                                }}
                              >
                                <MDBRow>
                                  <MDBCol
                                    className={`col-${colSize} text-center`}
                                  >
                                    <MDBContainer fluid>
                                      <MDBRow className="align-items-center">
                                        <MDBCol
                                          size={"12"}
                                          className={`mb-5 text-center`}
                                        >
                                          {buildImage ? (
                                            <img
                                              style={{
                                                width: "100%",
                                                height: "auto",
                                              }}
                                              src={buildUrl}
                                              alt="Product"
                                            />
                                          ) : (
                                            item.product.image?.url && (
                                              <img
                                                style={{
                                                  maxWidth: "100%",
                                                  height: "100rem",
                                                }}
                                                src={item.product?.image?.url}
                                                alt="Product"
                                              />
                                            )
                                          )}
                                        </MDBCol>
                                        <MDBCol>
                                          <MDBRow>
                                            <MDBCol
                                              className={`d-flex justify-content-start flex-column text-left`}
                                            >
                                              <MDBBox className="mb-3">
                                                <MDBTypography
                                                  tag="h4"
                                                  className="color-black"
                                                >
                                                  {item.product.sku}
                                                </MDBTypography>
                                              </MDBBox>
                                              <MDBBox className="mb-3">
                                                <MDBTypography
                                                  tag="h4"
                                                  className="color-black"
                                                >
                                                  {item.product.name}
                                                </MDBTypography>
                                              </MDBBox>
                                              {item?.product?.ffi_collection !==
                                                undefined &&
                                              productCollection !== "false" ? (
                                                <MDBBox className="mb-3">
                                                  <MDBTypography
                                                    tag="h2"
                                                    className={`fs-14 color-black`}
                                                  >
                                                    {productCollection}
                                                  </MDBTypography>
                                                </MDBBox>
                                              ) : (
                                                <></>
                                              )}
                                            </MDBCol>
                                            {themeConfiguration.magento_url !==
                                              "https://magento.fairfieldchair.com/" && (
                                              <MDBCol
                                                className={`d-flex justify-content-end`}
                                              >
                                                {item.prices?.price?.value && (
                                                  <p
                                                    className="text-default fwmedium m-0 color-black"
                                                    style={{
                                                      fontSize: largeFont,
                                                    }}
                                                  >
                                                    <Price
                                                      price={
                                                        item.prices?.price
                                                          ?.value
                                                      }
                                                      onlyValues={true}
                                                    />
                                                  </p>
                                                )}
                                              </MDBCol>
                                            )}
                                          </MDBRow>
                                        </MDBCol>
                                      </MDBRow>
                                    </MDBContainer>
                                  </MDBCol>
                                  {item.comment !== "" && (
                                    <MDBCol size="12">
                                      <MDBContainer>
                                        <MDBRow>
                                          <MDBCol size="12">
                                            <MDBTypography
                                              tag="h2"
                                              className={`fs-14 text-default mb-3 color-black`}
                                            >
                                              Comments
                                            </MDBTypography>
                                            <MDBTypography className="fs-14 text-primary color-black">
                                              {item.comment}
                                            </MDBTypography>
                                          </MDBCol>
                                        </MDBRow>
                                      </MDBContainer>
                                    </MDBCol>
                                  )}
                                </MDBRow>
                              </MDBCol>
                              {showOnePage ? null : (
                                <MDBCol size="12">
                                  <MDBContainer>
                                    <MDBRow
                                      className={`border-bottom-1 align-items-start m-0`}
                                    >
                                      <MDBCol
                                        size={"2"}
                                        className={`pdf-print-product-image mb-5 d-flex justify-content-start`}
                                      >
                                        {buildImage ? (
                                          <img
                                            style={{
                                              width: "100%",
                                              height: "auto",
                                            }}
                                            src={buildUrl}
                                            alt="Product"
                                          />
                                        ) : (
                                          item.product.small_image?.url && (
                                            <img
                                              style={{
                                                maxWidth: "100%",
                                                height: "10rem",
                                              }}
                                              src={
                                                item.product?.small_image?.url
                                              }
                                              alt="Product"
                                            />
                                          )
                                        )}
                                      </MDBCol>
                                      <MDBCol size="7">
                                        <MDBRow>
                                          <MDBCol
                                            className={`d-flex justify-content-start flex-column text-left`}
                                          >
                                            <MDBBox className="mb-3">
                                              <MDBTypography
                                                tag="h4"
                                                className="color-black"
                                              >
                                                {item.product.sku}
                                              </MDBTypography>
                                            </MDBBox>
                                            <MDBBox className="mb-3">
                                              <MDBTypography
                                                tag="h4"
                                                className="color-black"
                                              >
                                                {item.product.name}
                                              </MDBTypography>
                                            </MDBBox>
                                            {item?.product?.ffi_collection !==
                                              undefined &&
                                            productCollection !== "false" ? (
                                              <MDBBox className="mb-3">
                                                <MDBTypography
                                                  tag="h2"
                                                  className={`fs-14 color-black`}
                                                >
                                                  {productCollection}
                                                </MDBTypography>
                                              </MDBBox>
                                            ) : (
                                              <></>
                                            )}
                                          </MDBCol>
                                        </MDBRow>
                                      </MDBCol>
                                      <MDBCol
                                        size="3"
                                        className={`d-flex justify-content-end`}
                                      >
                                        <MDBTypography
                                          tag="h2"
                                          className={`fs-16 color-black`}
                                        >
                                          Selected Options
                                        </MDBTypography>
                                      </MDBCol>
                                    </MDBRow>
                                  </MDBContainer>
                                </MDBCol>
                              )}
                              {showOnePage ? null : (
                                <MDBCol size="12">
                                  <CustomizableOptions
                                    cartQuery={cartQuery}
                                    item={(item as unknown) as DomainCartItem}
                                    index={index}
                                    showOptionsWithCollapse={false}
                                    pdfBorderState={true}
                                  />
                                </MDBCol>
                              )}
                              <MDBCol
                                size="12"
                                className={
                                  "fixed-bottom justify-content-center footer-pdf-quote-inner "
                                }
                              >
                                <MDBRow className="footer-pdf-quote px-5">
                                  <MDBCol className={"footer-pdf-quote-inner"}>
                                    <MDBRow className="mb-5 border-top-1 border-black justify-content-center text-white align-content-center">
                                      <MDBCol
                                        size="12"
                                        className={`d-flex mt-5 justify-content-center`}
                                      >
                                        <MDBTypography className="mb-1 fs-16 body-4 color-black">
                                          FairfieldChair.com
                                        </MDBTypography>
                                      </MDBCol>
                                    </MDBRow>
                                  </MDBCol>
                                </MDBRow>
                              </MDBCol>
                            </React.Fragment>
                          );
                        })}
                      </MDBRow>
                    </MDBContainer>
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            </MDBRow>
          );
        })}
      </MDBContainer>
    );
  }
}

export default Print;
