let color = "#e88602";

export default {
  magento_url: "https://magento.fairfieldchair.com/",
  // model_url: "https://fairfieldchair-models.s3.us-east-2.amazonaws.com",
  model_url: "https://magento.fairfieldchair.com/media",
  graphql_url: "graphql",
  media_url: "/pub/media",
  catalog_url: "/catalog/product",
  category_url: "/catalog/category",
  app_url: "https://fairfieldchair.com/",
  name: "Fairfield Chair",
  name_homepage: "Fairfield Chair",
  root_category_id: 2,
  product_category_id: 2548,
  whats_new_category_id: 2665,
  fabric_category_id: 2705,
  fabric_subcategory_id: 2706,
  finish_category_id: 2761,
  finish_subcategory_id: 2762,
  finish_monogram_subcategory_id: 2763,
  monogram_category_id: 2649,
  industry_id: 5,
  sessionMessageTime: 4000,
  colors: {
    primary: color,
  },
  loader: {
    color: "#6c808b",
    height: 100,
    width: 100,
    type: "ThreeDots",
  },
  mediaGallery: {
    nav: "thumbs",
    desktop: {
      width: 800,
      height: 600,
    },
    mobile: {
      width: "auto",
      height: "auto",
    },
  },
  pricing: {
    defaultCurrency: "USD",
  },
  pageSizeOptions: [
    { label: "12", value: 12 },
    { label: "24", value: 24 },
    { label: "48", value: 48 },
    { label: "96", value: 96 },
  ],
};

export const storage = {
  local_storage_key: "fairfield",
  throttle_local_storage_time: 200,
};
