import { determineCurrency } from "config/currency";
import { number_format } from "core/components/price/price";
import { MDBCol, MDBRow, MDBTypography } from "mdbreact";
import React, { useCallback } from "react";
import { Optional } from "utility-types";
import { DomainCartItem } from "../models";
import themeConfiguration from "../../../../config/themeConfiguration";
import { useSelector } from "react-redux";

export interface CartSummaryItemProps {
  item: Optional<DomainCartItem, "quantity">;
}

const imageSize = 216;
const imageStyle: React.CSSProperties = {
  maxWidth: imageSize,
  maxHeight: imageSize,
};
const imageWrapperStyle: React.CSSProperties = {
  width: imageSize,
  height: imageSize,
};

const CartSummaryItem: React.FC<CartSummaryItemProps> = (props) => {
  // @ts-ignore
  const cart = useSelector((state) => state?.customer?.data?.cart);

  const getBYOImage = useCallback(
    (item) => {
      return (
        cart?.items
          // @ts-ignore
          ?.find((cartItem) => cartItem.id === item.id)
          ?.customizable_options?.find(
            // @ts-ignore
            (option) => option?.label === "Build your own image"
          )?.values?.[0]?.value
      );
    },
    [cart]
  );

  let foundImage: any;
  let foundUrl;
  props.item.customizable_options?.forEach((option) => {
    if (option?.label === "Build your own image") {
      foundImage = getBYOImage(props?.item);
    } else if (option?.label === "Build your own configurator url") {
      let url = option.values?.[0]?.value
        ?.replace(/&quot;/g, '\\"')
        .replace(/&amp;/g, "&");
      foundUrl = {
        pathname: url?.substr(0, url.indexOf("?")),
        search: url?.substring(url.indexOf("?"), url.length),
      };
    }
  });
  return (
    <MDBRow>
      <MDBCol className="d-flex align-items-center">
        <div
          className={`d-flex align-items-center justify-content-center ${
            props.item?.product?.sku &&
            props.item.product.sku.length <= 4 &&
            "finish-fix-image"
          }`}
          style={imageWrapperStyle}
        >
          {foundImage ? (
            <img
              className={
                foundImage?.includes("build_your_own_image")
                  ? "item-image-quote build-own"
                  : "item-image-quote"
              }
              src={themeConfiguration.magento_url + foundImage}
              alt={props.item?.product?.small_image?.label || ""}
              style={imageStyle}
            />
          ) : (
            props.item?.product?.small_image?.url &&
            props.item.product.small_image.label && (
              <img
                src={props.item.product.small_image.url}
                alt={props.item.product.small_image.label}
                style={imageStyle}
              />
            )
          )}
        </div>
        <MDBRow className="ml-4 pl-2">
          <MDBCol>
            <MDBTypography className="mb-0" tag="h4" variant="h4">
              {props.item.product.sku}
            </MDBTypography>
            {/* @ts-ignore */}
            <MDBTypography className="mt-3" variant="body-2">
              {props.item.product.name}
            </MDBTypography>
            {props.item.quantity && (
              <>
                {/* @ts-ignore */}
                <MDBTypography className="mt-3" variant="body-2">
                  QTY: {props.item.quantity}
                </MDBTypography>
              </>
            )}
            {/* @ts-ignore */}
            {themeConfiguration.magento_url !==
              "https://magento.fairfieldchair.com/" && (
              // @ts-ignore
              <MDBTypography className="mt-3" variant="body-1">
                Price: {determineCurrency(props.item.prices?.price.currency)}
                {number_format(props.item.prices?.price.value, 2)}
              </MDBTypography>
            )}
          </MDBCol>
        </MDBRow>
      </MDBCol>
    </MDBRow>
  );
};

export default CartSummaryItem;
