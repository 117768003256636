// TODO add back // @ts-heck

import Breadcrumbs from "app/layout/breadcrumbs";
import Select from "app/layout/Select";
import {
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBInput,
  MDBRow,
  MDBTypography,
} from "mdbreact";
import React from "react";
import { RepListing } from "./repListing";

export const RepLocatorDisplay = ({
  // @ts-ignore
  zipCode,
  // @ts-ignore
  setZipCode,
  // @ts-ignore
  industry,
  // @ts-ignore
  setIndustry,
  // @ts-ignore
  industryOptions = [],
  // @ts-ignore
  searchHandler,
  tempReps = [],
  isResult,
  // @ts-ignore
  canSearch,
}) => {
  return (
    <MDBContainer className="rep-locator">
      <MDBRow className="mb-5">
        <MDBCol size="12" className="my-2">
          <Breadcrumbs />
        </MDBCol>
        <MDBCol size="12">
          <MDBTypography tag="h1">Find a Sales Representative</MDBTypography>
        </MDBCol>
      </MDBRow>
      <MDBRow className="mb-5">
        <MDBCol
          size="12"
          lg="3"
          className="d-flex align-items-center pr-lg-0 justify-content-center"
        >
          <MDBInput
            value={zipCode}
            onChange={(e) => {
              //@ts-ignore
              setZipCode(e.target.value);
            }}
            className="w-100 border-sm"
            containerClass="w-100"
            hint="Enter your zip code"
          />
        </MDBCol>
        <MDBCol
          size="12"
          lg="3"
          className="d-flex align-items-center pr-lg-0 justify-content-center"
        >
          <Select
            placeholder={"Choose an Industry"}
            value={industry.value && industry}
            options={industryOptions}
            wrapperClass="w-100"
            // @ts-ignore
            onChange={(e) => {
              setIndustry(e);
            }}
          />
        </MDBCol>
        <MDBCol
          size="12"
          lg="3"
          className="d-flex mt-4 mt-lg-0 align-items-center justify-content-center"
        >
          <MDBBtn
            color="default"
            onClick={() => {
              searchHandler();
            }}
            className="w-100 m-0"
            disabled={!canSearch}
          >
            Search
          </MDBBtn>
        </MDBCol>
      </MDBRow>
      <RepListing tempReps={tempReps} isResult={isResult} />
      <MDBRow className="mt-5 rep-please-contact">
        <MDBCol className="d-flex align-items-center justify-content-center">
          <MDBTypography tag="p" className="body-2 d-inline h-100 text-center">
            For International Customers, please contact us at
            <a className="fs-16 mx-2" href="phone:828.758.5571">
              828.758.5571
            </a>
            or email us at{" "}
            <a
              className="link-3 fs-16"
              href="mailto:sales@fairfieldchair.com"
            >
              sales@fairfieldchair.com
            </a>
          </MDBTypography>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};
