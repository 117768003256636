import Select from "app/layout/Select";
import { MDBRow } from "mdbreact";
import React from "react";
import { selectIsLoggedIn } from "app/state/redux/data/customer/selectors";
import { useSelector } from "react-redux";
import themeConfiguration from "config/themeConfiguration";

export const DropdownOption = ({
  setCustomizationOption,
  customizationOptions,
  configFabricProps,
  nailData,
  option = { title: "", value: [{ title: "", option_type_id: 1 }], price: "" },
  dropdownOptions,
  setDropdownOptions,
  queryPathFabrics,
}) => {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  let localDropdownOptions = [];
  const [selectedValue, setSelectedValue] = React.useState(null);
  const [preselectedPrice, setPreselectedPrice] = React.useState(0);
  option.value.forEach((value, index) => {
    localDropdownOptions.push({
      label: `${value.title} \u00A0\u00A0  ${
        isLoggedIn
          ? value.price > 0 && isLoggedIn
            ? `${
                themeConfiguration.magento_url !==
                "https://magento.fairfieldchair.com/"
                  ? `+ $ ${value.price.toFixed(2)}`
                  : ""
              } `
            : "(standard)"
          : ""
      }`,
      value: value.option_type_id,
      price: value.price,
    });
    Object.keys(dropdownOptions).forEach((key) => {
      if (option.title.toLowerCase().split(" ").join("_") === key) {
        if (
          dropdownOptions[key]?.toString() === value.option_type_id.toString()
        ) {
          if (!selectedValue) {
            setSelectedValue(localDropdownOptions[index]);
          }
        }
      }
    });
    dropdownOptions["preselectedPrice"] = preselectedPrice;
  });

  React.useEffect(() => {
    setSelectedValue(localDropdownOptions.find((opt) => opt.price === 0));
  }, []);

  React.useEffect(() => {
    let price = 0;
    option.value.forEach((value) => {
      if (Object.keys(queryPathFabrics).length > 0) {
        Object.keys(queryPathFabrics).map((key) => {
          if (value.option_type_id === +queryPathFabrics[key]) {
            price += value.price;
          }
        });
      }
    });
    setPreselectedPrice(price);
    dropdownOptions["preselectedPrice"] = price;
  }, [selectedValue]);

  let setPlayerOption = () => {};

  if (nailData && option.title === "Nail Size") {
    setPlayerOption = nailData.setNailSize;
  }

  return (
    <MDBRow className="w-100 option-selection-dropdown">
      <Select
        placeholder={`${`Please Select ${option.title}`}`}
        wrapperClass={"w-100"}
        options={localDropdownOptions}
        value={selectedValue}
        onChange={(e) => {
          setCustomizationOption({
            id: option.option_id,
            value: e.value,
            price: e.price,
          });
          setPlayerOption(e.label);
          let localDropdownOptionsArr = JSON.parse(
            JSON.stringify(dropdownOptions)
          );

          Object.keys(dropdownOptions).forEach((key) => {
            if (option.title.toLowerCase().split(" ").join("_") === key) {
              localDropdownOptionsArr[key] = e.value;
            }
          });
          setDropdownOptions(localDropdownOptionsArr);
          setSelectedValue(e);
        }}
      />
    </MDBRow>
  );
};
