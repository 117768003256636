/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import Icon from "app/assets/icon/icon";
import { determineCurrency } from "config/currency";
import { MDBCol, MDBInput, MDBRow, MDBTypography } from "mdbreact";
import React from "react";
import { Link } from "react-router-dom";
import themeConfiguration from "config/themeConfiguration";
import { headers } from "../../config";
import { ItemProps } from "../../models";
import { getOptionsPrice } from "../../utils";
import CommentSection from "../CommentSection";
import CustomizableOptions from "../CustomizableOptions";
import ItemTitle from "../ItemTitle";
import OptionsPrice from "../OptionsPrice";
import RemoveItem from "../RemoveItem";
import Subtotal from "../Subtotal";
import UnitPrice from "../UnitPrice";
import DocumentInput from "../DocumentInput";
import { monogramLogic, powerOptionFunction } from "../utils";

const MobileItem: React.ForwardRefRenderFunction<
  HTMLInputElement,
  ItemProps
> = (
  {
    index,
    item,
    removeItemFromCart,
    form,
    comment = "",
    onCommentChange,
    documentNames,
    onDocumentUpload,
    isUploading,
    onRemoveDocument,
    cartQuery,
  },
  inputRef
) => {
  const currency = React.useMemo(
    () => determineCurrency(item.prices?.price.currency),
    [item]
  );

  const optionsPrice = React.useMemo(() => {
    return getOptionsPrice(item.customizable_options);
  }, [item]);

  const unitPrice = React.useMemo(
    () =>
      item.prices?.price.value ? item.prices?.price.value - optionsPrice : 0,
    [item, optionsPrice]
  );

  const subtotal = React.useMemo(
    () => (item.prices?.price.value ?? 1) * item.quantity,
    [item]
  );

  const quantityProps = form.getFieldProps("quantity");
  let foundImage: any;
  // @ts-ignore
  let foundUrl;
  let powerOption = "";
  let foundUrlEdit: any;
  const BYOvalue = item?.customizable_options?.find(
    // @ts-ignore
    (option) => option.label === "Build your own options"
  )?.values?.[0]?.value;

  let parsedValues = BYOvalue
    ? JSON.parse(BYOvalue?.replace(/\&quot;/g, `"`))
    : "";
  item.customizable_options?.forEach((option) => {
    if (
      // @ts-ignore
      option?.label === "Build your own options" &&
      option.values &&
      option?.values[0]?.value !== "{}"
    ) {
      powerOption = powerOptionFunction(option);
    }
    if (option?.label === "Build your own image") {
      foundImage = option.values?.[0]?.value;
    } else if (option?.label === "Build your own configurator url") {
      let url = option.values?.[0]?.value
        ?.replace(/&quot;/g, '\\"')
        .replace(/&amp;/g, "&");

      if (typeof url === "string" && url?.includes("monogram")) {
        url = monogramLogic(item);
      }
      if (powerOption) url = `${url}${powerOption}`;
      if (parsedValues?.base)
        url = `${url}&baseName=${parsedValues?.base?.name}`;
      foundUrl = {
        pathname: url?.substr(0, url.indexOf("?")),
        search: url?.substring(url.indexOf("?"), url.length),
      };
      foundUrlEdit = {
        pathname: url?.substr(0, url.indexOf("?")),
        search: url?.substring(url.indexOf("?"), url.length) + "&edit=true",
      };
    }
  });

  const foundUrlSearch = () => {
    // @ts-ignore
    if (foundUrlEdit?.search) {
      // @ts-ignore
      foundUrlEdit.search = `${foundUrlEdit?.search}?edit=true&item_id=${item.id}`;
    }
  };

  return (
    <MDBCol size="12" className="pb-4 mobile-cart-items border-bottom">
      <MDBRow className="item-container mt-5">
        <MDBCol className="item-image-container mb-5" size="6">
          {foundUrl && foundImage ? (
            <Link to={foundUrl}>
              <div className="position-relative">
                <img
                  className={`img-fluid ${
                    foundImage?.includes("build_your_own_image")
                      ? "item-image-container item-image-cart build-own"
                      : "item-image-container"
                  }`}
                  src={themeConfiguration.magento_url + foundImage}
                  alt={item.product?.small_image?.label || ""}
                />
              </div>
            </Link>
          ) : (
            item.product?.small_image?.url &&
            item.product.small_image?.label && (
              <Link to={foundUrl ? foundUrl : item.product.url_key || "#"}>
                <div className="position-relative">
                  <img
                    src={item.product.small_image.url}
                    className="img-fluid"
                    alt={item.product.small_image.label}
                  />
                </div>
              </Link>
            )
          )}
        </MDBCol>
        <MDBCol className="mb-5 d-flex align-items-center" size="6">
          <MDBRow>
            <MDBCol size="12">
              <MDBCol size={"12"}>
                <ItemTitle item={item} foundUrl={foundUrl} />
              </MDBCol>
              <MDBCol className="item-controls">
                <MDBRow>
                  <MDBCol
                    size="12"
                    className="item-remove-container d-flex justify-content-between"
                  >
                    <RemoveItem onRemove={removeItemFromCart} />
                  </MDBCol>
                </MDBRow>
              </MDBCol>
              <MDBCol className="item-controls">
                <MDBRow>
                  <MDBCol
                    size="12"
                    className="item-remove-container d-flex justify-content-between"
                  >
                    <Link
                      to={
                        foundUrlEdit
                          ? foundUrlEdit
                          : item.product.url_key +
                              "?edit=true&item_id=" +
                              item.id || "#"
                      }
                      style={{ float: "left" }}
                      onClick={foundUrlSearch}
                    >
                      <div
                        className="cursor-pointer d-flex align-items-center"
                        tabIndex={0}
                        role="button"
                      >
                        <Icon
                          icon="edit"
                          style={{ width: "32px", margin: "5px" }}
                        />
                        {/*    /!* @ts-ignore *!/*/}
                        <MDBTypography className="m-0 fs-13 fwregular">
                          Edit
                        </MDBTypography>
                      </div>
                    </Link>
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            </MDBCol>
          </MDBRow>
        </MDBCol>
        <MDBCol
          className="item-price-container d-flex align-items-center"
          size="12"
        >
          {themeConfiguration.magento_url !==
            "https://magento.fairfieldchair.com/" && (
            <MDBRow className="w-100 mb-5">
              <MDBCol className="item-price-container-label" size="6">
                {/* @ts-ignore */}
                <MDBTypography variant="body-2">
                  {headers[2].title}
                </MDBTypography>
              </MDBCol>
              <MDBCol size="6">
                <UnitPrice currency={currency} price={unitPrice} />
              </MDBCol>
            </MDBRow>
          )}
        </MDBCol>
        <MDBCol className="item-qty-container" size="12">
          <MDBRow className="w-100 mb-5">
            <MDBCol size="6" className="d-flex align-items-center">
              {/* @ts-ignore */}
              <MDBTypography variant="body-2">{headers[4].title}</MDBTypography>
            </MDBCol>
            <MDBCol
              size="6"
              className={`d-flex align-items-center${
                item.quantity === 1 ? " disable-minus" : ""
              }`}
            >
              <div className={`qty-wrapper d-flex justify-content-center`}>
                <div
                  className="qty-click qty-click-minus cursor-pointer"
                  onClick={() => {
                    if (quantityProps.value > 1) {
                      form.setFieldValue("quantity", quantityProps.value - 1);
                      form.submitForm();
                    }
                  }}
                >
                  <Icon icon={"minusBlue"} />
                </div>
                <MDBInput
                  id="quantity_input"
                  value={quantityProps.value}
                  hint="Quantity"
                  onFocus={(e) => e.currentTarget.select()}
                  onChange={(e) => {
                    if (parseInt(e.currentTarget.value) > 0) {
                      form.setFieldValue("quantity", +e.currentTarget.value);
                      form.submitForm();
                    }
                  }}
                  containerClass={`m-0 w-auto d-flex`}
                  type={`number`}
                />
                <div
                  className="qty-click qty-click-plus cursor-pointer"
                  onClick={() => {
                    form.setFieldValue("quantity", quantityProps.value + 1);
                    form.submitForm();
                  }}
                >
                  <Icon icon={"plusBlue"} />
                </div>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBCol>
        <MDBCol className="d-flex align-items-center" size="12">
          <MDBRow className="w-100 mb-5 ml-0">
            <MDBCol size="6" className="pl-0 item-options-container-label">
              {/* @ts-ignore */}
              <MDBTypography variant="body-2">{headers[3].title}</MDBTypography>
            </MDBCol>
            <MDBCol size="6" className="pl-0">
              {themeConfiguration.magento_url !==
                "https://magento.fairfieldchair.com/" && (
                <OptionsPrice currency={currency} price={optionsPrice} />
              )}
            </MDBCol>
          </MDBRow>
        </MDBCol>
        <MDBCol className="d-flex align-items-center" size="12">
          {themeConfiguration.magento_url !==
            "https://magento.fairfieldchair.com/" && (
            <MDBRow className="w-100 mb-3 ml-0">
              <MDBCol size="6" className="pl-0 item-subtotal-container-label">
                {/* @ts-ignore */}
                <MDBTypography variant="body-2">
                  {headers[5].title}
                </MDBTypography>
              </MDBCol>

              <MDBCol size="6" className="pl-0">
                <Subtotal currency={currency} price={subtotal} />
              </MDBCol>
            </MDBRow>
          )}
        </MDBCol>
      </MDBRow>

      <CustomizableOptions
        cartQuery={cartQuery}
        item={item}
        showOptionsWithCollapse={true}
        index={index}
        pdfBorderState={false}
      />

      <CommentSection
        lg="12"
        comment={comment}
        onCommentChange={onCommentChange}
      />

      <MDBRow className="mt-5 pt-5 mb-3 mobile-item-files">
        <MDBCol>
          <DocumentInput
            documentNames={documentNames}
            onDocumentUpload={onDocumentUpload}
            isUploading={isUploading}
            onRemoveDocument={onRemoveDocument}
          />
        </MDBCol>
      </MDBRow>
    </MDBCol>
  );
};

export default React.forwardRef<HTMLInputElement, ItemProps>(MobileItem);
