// import Icon from "app/assets/icon/icon";
import Breadcrumbs from "app/layout/breadcrumbs";
import CmsBlock from "app/layout/cmsBlock";
// import Logo from "app/layout/logo";
import { MDBBox, MDBCol, MDBContainer, MDBRow } from "mdbreact";
import React from "react";
import ContactForm from "./contactForm";

const Contact = (props) => {
  return (
    <MDBContainer fluid className="static-page contact-us">
      <MDBRow>
        <MDBCol size="12" className="p-0">
          <CmsBlock id={"careers_main_image"} />
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol size="12" className="p-0">
          <MDBContainer>
            <MDBRow>
              <MDBCol size="12" className="mt-5">
                <Breadcrumbs />
              </MDBCol>
            </MDBRow>
            <CmsBlock id="contact_title" />
            {/* NEED TO CHECK PARSER FOR ICONS */}
            <CmsBlock id="contact_address_img" />
            {/* <MDBRow className="py-5 mt-5 px-4 bg-gray-lighten-4">
              <MDBCol size="12" lg="8" xl="9" className="mb-lg-0 mb-5">
                <img
                  className="img-fluid w-100"
                  src="http://magento.fairfieldchair.com/media/wysiwyg/Contact-Top-Image.png"
                  alt="Contact Us Banner"
                />
              </MDBCol>
              <MDBCol size="12" lg="4" xl="3" className="">
                <MDBBox className="h-100 d-flex justify-content-center align-items-center bg-white">
                  <MDBBox className="p-5">
                    <MDBBox className={`mb-5`}>
                      <Logo />
                    </MDBBox>
                    <MDBBox className="text-left">
                      <MDBBox className="d-flex align-items-baseline my-4">
                        <Icon icon="address" />
                        <MDBTypography
                          tag="p"
                          className="text-primary fs-16 fwregular ml-4"
                        >
                          Post Office Box 1710 <br />
                          Lenoir, North Carolina 28645
                        </MDBTypography>
                      </MDBBox>
                      <MDBBox className="d-flex align-items-baseline my-4">
                        <Icon icon="phone" />
                        <MDBTypography
                          tag="p"
                          className="text-primary fs-16 fwregular ml-4"
                        >
                          828.758.5571
                        </MDBTypography>
                      </MDBBox>
                      <MDBBox className="d-flex align-items-baseline my-4">
                        <Icon icon="fax" />
                        <MDBTypography
                          tag="p"
                          className="text-primary fs-16 fwregular ml-4"
                        >
                          828.758.5571
                        </MDBTypography>
                      </MDBBox>
                      <MDBBox className="d-flex align-items-baseline my-4">
                        <Icon icon="email" />
                        <a
                          href={"mailto:careers@fairfieldchair.com"}
                          className="link-2 body-1 ml-4"
                        >
                          careers@fairfieldchair.com
                        </a>
                      </MDBBox>
                    </MDBBox>
                  </MDBBox>
                </MDBBox>
              </MDBCol>
            </MDBRow> */}
            <MDBRow className="my-5">
              <MDBBox className="separator dark" />
            </MDBRow>
          </MDBContainer>
          <MDBContainer>
            <MDBRow>
              <ContactForm {...props} />
            </MDBRow>
          </MDBContainer>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default Contact;
