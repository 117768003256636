import { Collapse } from "app/layout/Collapse";
import { determineCurrency } from "config/currency";
import { number_format } from "core/components/price/price";
import { MDBBox, MDBCol, MDBRow, MDBTypography } from "mdbreact";
import { nailFinishArray } from "app/pages/Custom/haven/data";
import { frameNailSizeArray } from "app/pages/Custom/haven/data";
import React from "react";
import { useQuery } from "react-apollo-hooks";
import { DomainCartItem } from "../models";
import { QUERYOPTIONS } from "../queries";
import themeConfiguration from "config/themeConfiguration";
// @ts-ignore
import fabrics from "generation/fabrics";
export interface CustomizableOptionsProps {
  item: DomainCartItem;
  index: number;
  cartQuery: any;
  showOptionsWithCollapse: boolean;
  pdfBorderState: boolean;
}

const CustomizableOptions: React.FC<CustomizableOptionsProps> = (props) => {
  /* @ts-ignore */
  let cartItems = props.cartQuery?.data?.customerCart.items;
  // @ts-ignore
  let optionImage;
  let finishImage: any;
  let optionName = "";
  let optionGrade = "";
  let findInFabrics: boolean = false;
  let items = cartItems?.map((item: any) =>
    item.customizable_options?.map((custOpt: any) =>
      custOpt?.values?.map((value: any) => value.value)
    )
  );

  const flattenedArray = items?.flat(Infinity);

  let filter = {
    filters: { sku: { in: flattenedArray } },
  };
  let { data, loading } = useQuery(QUERYOPTIONS, {
    variables: filter,
    fetchPolicy: "no-cache",
  });

  if (loading) {
    return <></>;
  }
  let dataQuery = data?.products?.items;

  let localCustomizableOptions = props?.item?.customizable_options?.length
    ? JSON.parse(JSON.stringify(props?.item?.customizable_options))
    : [];

  let buildYourOwnCustomizableOptions: Array<any> = [];

  props.item.customizable_options.forEach((option) => {
    if (
      option?.label === "Build your own options" &&
      option.values &&
      option?.values[0]?.value !== "{}"
    ) {
      let parsedValues = option?.values[0]?.value
        ? JSON.parse(option?.values[0]?.value?.replace(/\&quot;/g, `"`))
        : "";

      const isRecliner = parsedValues?.frame?.name
        ?.toLowerCase()
        ?.includes("recliner");
      const isPowerOption = parsedValues?.powerOption?.id ? true : false;
      if (isPowerOption) {
        if (!isRecliner) delete parsedValues?.["powerOption"];
        else {
          delete parsedValues?.["base"];
          delete parsedValues?.["seatAndBack"];
        }
      }
      if (parsedValues !== "") {
        if (parsedValues?.model && !parsedValues?.model?.striping)
          delete parsedValues?.striping;
        if (parsedValues?.model && !parsedValues?.model?.contrastDrawers)
          delete parsedValues?.contrastDrawers;
        Object.keys(parsedValues).forEach((customOption: any) => {
          if (
            customOption !== "frame" &&
            customOption !== "floor" &&
            customOption !== "model" && // for products that belong to the Monogram collection
            parsedValues[customOption] !== "X"
          ) {
            buildYourOwnCustomizableOptions.push({
              parentName:
                customOption.toLowerCase().indexOf("accessory") !== -1
                  ? customOption === "accessoryLeft"
                    ? "Accessory Left"
                    : "Accessory Right"
                  : customOption === "finish"
                  ? "Finish"
                  : parsedValues[customOption].parentName,
              label: parsedValues[customOption].name,
              image: parsedValues[customOption].image,
            });
          }
        });
      }
    }
  });

  let deleteArr: Array<number> = [];

  localCustomizableOptions.forEach((option: any, index: number) => {
    if (
      option.label.toLowerCase().indexOf("build") !== -1 ||
      option.label.toLowerCase() === "upholstery options"
    ) {
      deleteArr.push(index);
    }
    if (
      option.label.toLowerCase() === "contrasting buttons" &&
      option.type === "checkbox"
    ) {
      localCustomizableOptions.forEach(
        (contrastOption: any, contrastIndex: number) => {
          if (
            contrastOption.label.toLowerCase() === "contrasting buttons" &&
            contrastOption.type !== "checkbox"
          ) {
            localCustomizableOptions[contrastIndex].values[0].price.value =
              option.values[0].price.value;
          }
        }
      );
    }
    if (
      option.label.toLowerCase().indexOf("contrast welt") !== -1 &&
      option.type === "checkbox" &&
      option.label.toLowerCase().indexOf("throw pillow") === -1
    ) {
      localCustomizableOptions.forEach((contrastOption: any) => {
        if (
          contrastOption.label.toLowerCase() === "contrasting welt" &&
          contrastOption.type !== "checkbox"
        ) {
          contrastOption.values[0].price.value = option.values[0].price.value;
        }
      });
    }
    if (
      option.label.toLowerCase().indexOf("decorative cord") !== -1 &&
      option.type === "checkbox" &&
      option.label.toLowerCase().indexOf("throw pillow") === -1
    ) {
      localCustomizableOptions.forEach((contrastOption: any) => {
        if (
          contrastOption.label.toLowerCase() === "decorative cord" &&
          contrastOption.type !== "checkbox"
        ) {
          contrastOption.values[0].price.value = option.values[0].price.value;
        }
      });
    }
    if (
      option.label.toLowerCase().indexOf("fringe") !== -1 &&
      option.type === "checkbox" &&
      option.label.toLowerCase().indexOf("throw pillow") === -1
    ) {
      localCustomizableOptions.forEach((contrastOption: any) => {
        if (
          contrastOption.label.toLowerCase() === "fringe" &&
          contrastOption.type !== "checkbox"
        ) {
          contrastOption.values[0].price.value = option.values[0].price.value;
        }
      });
    }
    if (
      option.label.toLowerCase().indexOf("throw pillow contrast welt") !== -1 &&
      option.type === "checkbox"
    ) {
      localCustomizableOptions.forEach((contrastOption: any) => {
        if (
          contrastOption.label.toLowerCase() === "throw pillow contrast welt" &&
          contrastOption.type !== "checkbox"
        ) {
          contrastOption.values[0].price.value = option.values[0].price.value;
        }
      });
    }
    if (
      option.label.toLowerCase().indexOf("throw pillow decorative cord") !==
        -1 &&
      option.type === "checkbox"
    ) {
      localCustomizableOptions.forEach((contrastOption: any) => {
        if (
          contrastOption.label.toLowerCase() ===
            "throw pillow decorative cord" &&
          contrastOption.type !== "checkbox"
        ) {
          contrastOption.values[0].price.value = option.values[0].price.value;
        }
      });
    }
    if (
      option.label.toLowerCase().indexOf("throw pillow fringe") !== -1 &&
      option.type === "checkbox"
    ) {
      localCustomizableOptions.forEach((contrastOption: any) => {
        if (
          contrastOption.label.toLowerCase() === "throw pillow fringe" &&
          contrastOption.type !== "checkbox"
        ) {
          contrastOption.values[0].price.value = option.values[0].price.value;
        }
      });
    }
  });
  deleteArr.forEach(() => {
    localCustomizableOptions.splice(0, 1);
  });

  localCustomizableOptions = localCustomizableOptions.filter(
    (option: any) =>
      option.type !== "checkbox" ||
      (option.type === "checkbox" &&
        option.label.toLowerCase().search("(contras)\\w+ welt|buttons") ===
          -1 &&
        option.label.toLowerCase().indexOf("decorative cord") === -1 &&
        option.label.toLowerCase().indexOf("fringe") === -1)
  );

  return (
    <>
      {buildYourOwnCustomizableOptions.length ? (
        <Collapse
          title="Build Selections"
          isOpen={props.showOptionsWithCollapse ? props.index === 0 : true}
          className="mt-4 pt-2"
          pdfBorder={!props?.pdfBorderState ? props?.pdfBorderState : true}
        >
          <MDBRow className="justify-content-between flex-column">
            {buildYourOwnCustomizableOptions
              ?.filter((option) => option.label)
              ?.map((option: any) => {
                const optionFabric = dataQuery.find(
                  (fetchedFabrics: any) =>
                    fetchedFabrics.sku === option.values?.[0]?.value || ""
                );
                if (
                  optionFabric &&
                  optionFabric.thumbnail?.url?.includes("placeholder") &&
                  optionFabric.small_image?.url !== undefined
                ) {
                  optionImage = optionFabric.small_image.url;
                } else if (
                  optionFabric &&
                  optionFabric.thumbnail?.url?.includes("placeholder") &&
                  optionFabric.small_image?.url === undefined &&
                  optionFabric.image?.url !== undefined
                ) {
                  optionImage = optionFabric.image.url;
                } else if (optionFabric?.thumbnail.url) {
                  optionImage = optionFabric?.thumbnail.url;
                } else {
                  optionImage = option.image;
                }
                if (optionFabric && optionFabric?.name !== undefined) {
                  optionName = optionFabric?.name;
                }
                return (
                  <MDBCol key={option?.id} size="12" lg="6">
                    <MDBRow
                      key={option?.id}
                      className="mt-4 align-items-center"
                    >
                      {props.item.product.name?.includes("Ottoman") &&
                      (option?.parentName === "Arm" ||
                        option?.parentName === "Back Cushion") ? null : (
                        <MDBCol>
                          {/* @ts-ignore */}
                          <MDBTypography variant="body-2 color-black">
                            {option?.parentName}
                          </MDBTypography>
                        </MDBCol>
                      )}
                      <MDBCol className="d-flex justify-content-start">
                        <MDBRow>
                          {props.item.product.name?.includes("Ottoman") &&
                          (option?.parentName === "Arm" ||
                            option?.parentName === "Back Cushion") ? null : (
                            <MDBCol className={`d-flex align-items-center`}>
                              {(optionImage &&
                                option.parentName !== "Size" && (
                                  <MDBTypography
                                    /*@ts-ignore*/
                                    variant="body-4"
                                    className={`mr-4`}
                                  >
                                    <img
                                      src={
                                        typeof optionImage === "string"
                                          ? optionImage
                                          : optionImage?.url
                                      }
                                      alt={option?.label}
                                      style={{ width: "25px", height: "25px" }}
                                      className={`border-radius-05 ${
                                        option?.label &&
                                        option.label
                                          .toLowerCase()
                                          .indexOf("finish") !== -1 &&
                                        "finish-fix-cart-image"
                                      }`}
                                    />
                                  </MDBTypography>
                                )) || <></>}
                              {option.label ? (
                                option.parentName === "Size" ? (
                                  <MDBTypography
                                    className={`body-4 ${
                                      props?.pdfBorderState
                                        ? ""
                                        : "text-primary"
                                    } fwregular mb-0 color-black`}
                                  >
                                    <span className="">
                                      H {props.item?.product?.ffi_frmdimh}{" "}
                                    </span>
                                    <span className="">
                                      W {props.item?.product?.ffi_frmdimw}{" "}
                                    </span>
                                    <span className="">
                                      D {props.item?.product?.ffi_frmdimw}{" "}
                                    </span>
                                  </MDBTypography>
                                ) : (
                                  <MDBTypography
                                    className={`body-2 ${
                                      props?.pdfBorderState
                                        ? ""
                                        : "text-primary"
                                    } fwregular mb-0 color-black`}
                                  >
                                    {option.label}
                                  </MDBTypography>
                                )
                              ) : (
                                <></>
                              )}
                            </MDBCol>
                          )}
                        </MDBRow>
                      </MDBCol>
                      <MDBCol className="d-flex justify-content-end">
                        {props.item.product.name?.includes("Ottoman") &&
                        (option?.parentName === "Arm" ||
                          option?.parentName === "Back Cushion") ? null : (
                          <MDBTypography
                            /* @ts-ignore */
                            variant="body-1"
                            className={`fs-14 color-black`}
                          >
                            <>
                              {option.values &&
                              option?.values?.[0]?.price?.value !== 0
                                ? "+ " +
                                  determineCurrency(
                                    props.item.prices?.price.currency
                                  ) +
                                  number_format(
                                    option?.values?.[0]?.price?.value,
                                    2
                                  )
                                : "(standard)"}
                            </>
                          </MDBTypography>
                        )}
                      </MDBCol>
                    </MDBRow>
                  </MDBCol>
                );
              })}
          </MDBRow>
        </Collapse>
      ) : (
        <></>
      )}
      {localCustomizableOptions.length &&
      !props.item.customizable_options
        .find(
          // @ts-ignore
          (option) => option.label === "Build your own configurator url"
        )
        ?.values?.[0]?.value?.includes("/build-your-own/monogram") ? (
        <Collapse
          title="General Selections"
          isOpen={props.showOptionsWithCollapse ? props.index === 0 : true}
          className={`mt-4 pt-2`}
          pdfBorder={!props?.pdfBorderState ? props?.pdfBorderState : true}
        >
          <MDBRow className="justify-content-between">
            {localCustomizableOptions?.map((option: any) => {
              const optionFabric = dataQuery.find(
                (fetchedFabrics: any) =>
                  fetchedFabrics.sku === option.values?.[0]?.value || ""
              );

              if (option && !optionFabric) {
                const findInFabric =
                  fabrics?.find(
                    // @ts-ignore
                    (fabric) => {
                      if (option?.label === "Nail Band") return false;
                      return fabric?.name === option?.label;
                    }
                  ) ??
                  fabrics?.find(
                    // @ts-ignore
                    (fabric) => fabric?.name === "Fabric"
                  );

                let findFabricBySku = findInFabric?.products?.items?.find(
                  // @ts-ignore
                  (fabric) => fabric.sku === option.values?.[0]?.value
                );

                if (!findFabricBySku)
                  findFabricBySku = fabrics
                    ?.find(
                      // @ts-ignore
                      (fabric) => fabric?.name === "Leather"
                    )
                    ?.products?.items?.find(
                      // @ts-ignore
                      (fabric) => fabric.sku === option.values?.[0]?.value
                    );

                optionImage = findFabricBySku?.image?.url;
                optionName = findFabricBySku?.name;
                optionGrade = findFabricBySku?.grade[0];
                findInFabrics = true;
              } else {
                findInFabrics = false;
              }
              if (
                optionFabric &&
                optionFabric.thumbnail?.url?.includes("placeholder") &&
                optionFabric.small_image?.url !== undefined
              ) {
                optionImage = optionFabric.small_image.url;
              } else if (
                optionFabric &&
                optionFabric.thumbnail?.url?.includes("placeholder") &&
                optionFabric.small_image?.url === undefined &&
                optionFabric.image?.url !== undefined
              ) {
                optionImage = optionFabric.image.url;
              } else if (optionFabric?.thumbnail.url) {
                optionImage = optionFabric?.thumbnail.url;
              } else if (option?.label === "Nail Size") {
                let selectedNailSize = frameNailSizeArray.filter(
                  (size) => size.name === option?.values[0].label
                );
                optionImage = selectedNailSize[0]?.map;
              } else {
                if (!findInFabrics) optionImage = option.image;
              }
              if (optionFabric && optionFabric?.name !== undefined) {
                optionName = optionFabric?.name;
              }
              if (optionFabric && optionFabric?.grade[0] !== undefined) {
                optionGrade = optionFabric?.grade[0];
              }
              if (option?.label === "Nail Finish") {
                let selectedNailFinish = nailFinishArray.filter(
                  (finish) => finish.name === option?.values[0].value
                );
                finishImage = selectedNailFinish[0]?.map;
              }

              return (
                <MDBCol key={option?.id} size={"12"} lg={"6"}>
                  <MDBRow key={option?.id} className="mt-4 align-items-start">
                    <MDBCol>
                      {/* @ts-ignore */}
                      <MDBTypography variant="body-2" className="color-black">
                        {option?.label}
                      </MDBTypography>
                    </MDBCol>
                    <MDBCol className="d-flex justify-content-start">
                      <MDBRow>
                        <MDBCol className={`d-flex align-items-start`}>
                          {/* @ts-ignore */}
                          {optionImage ? (
                            <MDBTypography
                              /* @ts-ignore */
                              variant="body-4"
                              className={`mr-4 color-black`}
                            >
                              <img
                                src={
                                  (option?.label === "Nail Finish" &&
                                    //@ts-ignore
                                    finishImage) ||
                                  // @ts-ignore
                                  optionImage
                                }
                                alt={option?.label}
                                style={{ width: "25px", height: "25px" }}
                                className={`border-radius-05 ${
                                  option?.label &&
                                  option.label
                                    .toLowerCase()
                                    .indexOf("finish") !== -1 &&
                                  "finish-fix-cart-image"
                                }`}
                              />
                            </MDBTypography>
                          ) : (
                            <></>
                          )}
                          <MDBBox>
                            {optionName && option?.type === "field" ? (
                              <MDBTypography
                                className={`body-2 mb-2 color-black ${
                                  props?.pdfBorderState
                                    ? "font-weight-bold"
                                    : "text-primary"
                                }`}
                              >
                                {optionName}
                              </MDBTypography>
                            ) : (
                              <></>
                            )}
                            {optionGrade &&
                            optionGrade !== "false" &&
                            option?.type === "field" ? (
                              <MDBTypography
                                className={`body-2 ${
                                  props?.pdfBorderState
                                    ? "font-weight-bold"
                                    : "text-primary"
                                } mb-2 color-black`}
                              >
                                Grade {optionGrade}
                              </MDBTypography>
                            ) : (
                              <></>
                            )}
                            {option?.type === "checkbox" ||
                            option?.type === "drop_down" ? (
                              <MDBTypography
                                className={`body-2 ${
                                  props?.pdfBorderState
                                    ? "font-weight-bold"
                                    : "text-primary"
                                } mb-0 color-black`}
                              >
                                {option?.values[0].label}
                              </MDBTypography>
                            ) : (
                              <MDBTypography
                                className={`body-2 ${
                                  props?.pdfBorderState
                                    ? "font-weight-bold"
                                    : "text-primary"
                                } mb-0 color-black`}
                              >
                                SKU {option?.values[0].value}
                              </MDBTypography>
                            )}
                          </MDBBox>
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>
                    {themeConfiguration.magento_url !==
                      "https://magento.fairfieldchair.com/" && (
                      <MDBCol className="d-flex justify-content-end">
                        <MDBTypography
                          /* @ts-ignore */
                          variant="body-1"
                          className={`fs-14 color-black`}
                        >
                          <>
                            {option.values &&
                            option?.values?.[0]?.price?.value !== 0
                              ? "+ " +
                                determineCurrency(
                                  props.item.prices?.price.currency
                                ) +
                                number_format(
                                  option?.values?.[0]?.price?.value,
                                  2
                                )
                              : option.type !== "field"
                              ? "(standard)"
                              : ""}
                          </>
                        </MDBTypography>
                      </MDBCol>
                    )}
                  </MDBRow>
                </MDBCol>
              );
            })}
          </MDBRow>
        </Collapse>
      ) : (
        <></>
      )}
    </>
  );
};

export default CustomizableOptions;
