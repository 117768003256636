import Icon from "app/assets/icon/icon";
import Switch from "app/layout/Switch";
import themeConfiguration from "config/themeConfiguration";
import Price from "core/components/price";
import useCustomer from "core/state/hooks/customerHooks/useCustomer";
import { MDBBtn, MDBCol, MDBRow, MDBTypography } from "mdbreact";
import React, { useCallback, useContext, useEffect, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import { CheckBoxContext } from "../checkboxContext";

export const MoreOptionsSidebar = ({
  hideModal,
  options,
  customizationProps,
  queryPathFabrics,
}) => {
  const {
    checkBoxOptions,
    setCheckBoxOptions,
    // viewMoreOptions,
    setViewMoreOptions,
  } = useContext(CheckBoxContext);

  let initInternalOptions = useCallback(
    () =>
      options.map((o) => {
        let checked = false;
        if (Object.keys(queryPathFabrics).length > 0) {
          Object.keys(queryPathFabrics).forEach((key) => {
            if (o.title.toLowerCase().split(" ").join("_") === key) {
              checked = true;
            }
          });
        } else {
          Object.keys(checkBoxOptions).forEach((key) => {
            console.log(checkBoxOptions[key]);
            console.log(key);
            if (o.title.toLowerCase().split(" ").join("_") === key) {
              checked = checkBoxOptions[key];
            }
          });
        }
        return {
          id: o.option_id,
          value: o.value,
          checked: checked,
          title: o.title,
        };
      }),
    [options, queryPathFabrics]
  );

  const [internalOptions, setInternalOptions] = React.useState(
    initInternalOptions
  );

  const setInternalOption = (o, checked) => {
    let newInternalState = [];
    internalOptions.forEach((io) => {
      if (checked !== io.checked && o.id === io.id) {
        io.checked = !io.checked;
      }
      newInternalState.push(io);
    });
    setInternalOptions(newInternalState);
  };

  let [, isLoggedIn] = useCustomer();

  useEffect(() => {
    let currentOptions = customizationProps.customizationOptions;

    let oldOptions = currentOptions.filter((co) => {
      let keep = true;
      internalOptions.forEach((io) => {
        if (io.id === co.id) {
          keep = false;
        }
      });
      return keep;
    });

    // New options === Selected without More Options

    let addItems = internalOptions.filter((io) => {
      return io.checked;
    });

    let cartAddItems = addItems.map((o) => {
      return {
        id: o.id,
        value_string: o.value[0].option_type_id.toString(),
      };
    });

    // let allOptions = cartAddItems.concat(oldOptions);

    // customizationProps.setCustomizationOptions(allOptions);
    if (setViewMoreOptions) {
      console.log(addItems);
      setViewMoreOptions(addItems);
    }
  }, [internalOptions, customizationProps, setViewMoreOptions]);

  const reference = useRef(null);
  const onClickOutside = React.useCallback(
    (e) => {
      if (!reference.current.contains(e.target)) {
        hideModal();
      }
    },
    [reference.current]
  );

  useEffect(() => {
    document.addEventListener("click", onClickOutside);
    return () => {
      document.removeEventListener("click", onClickOutside);
    };
  });
  let isMobile = useMediaQuery({ maxWidth: 576 });

  return (
    <div ref={reference} className="more-options-sidebar">
      <MDBRow
        style={{
          backgroundColor: "white",
          position: "fixed",
          zIndex: 9999,
          top: 0,
          right: 0,
          height: "100vh",
          width: "56rem",
          boxShadow: "-3px 0px 6px #00000062",
        }}
      >
        <MDBCol className="d-flex flex-column h-100">
          <MDBRow>
            <MDBCol className="bg-default flex-center py-5 ">
              <Icon
                icon="closeWhite"
                size="4"
                style={{
                  position: "absolute",
                  top: "calc(50% - 20px)",
                  left: "5%",
                }}
                onClick={hideModal}
              />

              <MDBTypography
                tag="h2"
                className={`body-3 ${!isMobile ? "fs-40" : "fs-24"}`}
              >
                Select More Options
              </MDBTypography>
            </MDBCol>
          </MDBRow>
          <MDBRow className="p-3 flex-grow-1" style={{ overflowY: "scroll" }}>
            <MDBCol>
              {internalOptions.map((option) => {
                return (
                  <MDBRow key={option.id} className="px-3">
                    <MDBCol className="border-bottom py-3">
                      <MDBRow>
                        <MDBCol size="10">
                          <Switch
                            label={option.title}
                            checked={option.checked}
                            onChange={(e) => {
                              let x = !option.checked;
                              setInternalOption(option, !option.checked);
                              let localCheckBoxOptions = JSON.parse(
                                JSON.stringify(checkBoxOptions)
                              );
                              Object.keys(localCheckBoxOptions).forEach(
                                (key) => {
                                  if (
                                    option.title
                                      .toLowerCase()
                                      .split(" ")
                                      .join("_") === key
                                  ) {
                                    localCheckBoxOptions[key] = x;
                                  }
                                }
                              );
                              // console.log(localCheckBoxOptions);
                              setCheckBoxOptions({ ...localCheckBoxOptions });
                            }}
                          />
                        </MDBCol>
                        {isLoggedIn() &&
                          themeConfiguration.magento_url !==
                            "https://magento.fairfieldchair.com/" && (
                            <MDBCol size="2" className="flex center">
                              <Price
                                price={option.value[0].price}
                                type={"fixed"}
                              />
                            </MDBCol>
                          )}
                      </MDBRow>
                    </MDBCol>
                  </MDBRow>
                );
              })}
            </MDBCol>
            <MDBCol
              size="12"
              className="d-flex justify-content-center align-items-end"
            >
              <MDBBtn
                className="btn-primary continue btn-continue"
                onClick={() => hideModal(true)}
              >
                Continue
              </MDBBtn>
            </MDBCol>
          </MDBRow>
        </MDBCol>
      </MDBRow>
    </div>
  );
};
